@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html,
  body {
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
  }

  html {
    font-size: 14px;
  }

  @screen xl {
    html {
      font-size: 16px;
    }
  }

  /* // Typography */
  .h1 {
    @apply text-4xl font-extrabold leading-tight tracking-tighter;
  }

  .h2 {
    @apply text-3xl font-extrabold leading-tight tracking-tighter;
  }

  .h3 {
    @apply text-3xl font-bold leading-tight;
  }

  .h4 {
    @apply text-2xl font-bold leading-snug tracking-tight;
  }

  @screen md {
    .h1 {
      @apply text-5xl;
    }

    .h2 {
      @apply text-4xl;
    }
  }

  /* // Buttons */
  .btn,
  .btn-sm {
    @apply font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out;
  }

  .btn {
    @apply px-8 py-3 shadow-lg;
  }

  .btn-sm {
    @apply px-4 py-2 shadow;
  }

  /* // Pulsing animation */
  @keyframes pulseLoop {
    0% {
      opacity: 0.15;
      transform: scale(1) translateZ(0);
    }
    30% {
      opacity: 0.15;
    }
    60% {
      opacity: 0;
    }
    80% {
      opacity: 0;
      transform: scale(1.8) translateZ(0);
    }
  }
  @keyframes pulseMiniLoop {
    0% {
      opacity: 0;
      transform: scale(1) translateZ(0);
    }
    30% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.3;
    }
    80% {
      opacity: 0;
      transform: scale(3) translateZ(0);
    }
  }
  .pulse {
    transform: scale(1);
    opacity: 0;
    transform-origin: center;
    animation: pulseLoop 10000ms linear infinite;
  }
  .pulse-mini {
    animation: pulseMiniLoop 6000ms linear infinite;
  }
  .pulse-1 {
    animation-delay: -3000ms;
  }
  .pulse-2 {
    animation-delay: -6000ms;
  }

  /* // Animations delay */
  .animation-delay-500 {
    animation-delay: 500ms !important;
  }

  .animation-delay-1000 {
    animation-delay: 1000ms !important;
  }

  .translate-z-0 {
    transform: translateZ(0);
  }

  /* Tooltip */
  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  .arrow::before {
    content: "";
    transform: rotate(45deg);
    background: #eaeaea;
  }

  .tooltip[data-popper-placement^="top"] > .arrow {
    bottom: -4px;
  }

  .tooltip[data-popper-placement^="bottom"] > .arrow {
    top: -4px;
  }

  .tooltip[data-popper-placement^="left"] > .arrow {
    right: -4px;
  }

  .tooltip[data-popper-placement^="right"] > .arrow {
    left: -4px;
  }

  /* // Custom AOS animations */
  [data-aos="zoom-y-out"] {
    transform: scaleX(1.03);
    opacity: 0;
    transition-property: transform, opacity;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
